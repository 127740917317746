import React from "react";
import { LegalSection } from "../sections/Legal";
import { Page } from "../components/Page";
import { Seo } from "../components/Seo";

export default function ImprintPage() {
    return (
        <>
            <Seo title="Imprint" useTitleTemplate={true} noIndex={true} />
            <Page>
                <LegalSection sectionId="imprint" heading="Imprint" />
            </Page>
        </>
    );
}
